import Vue from 'vue'
import VCharts from 'v-charts'
Vue.use(VCharts)
export default {
    name: 'tpl_12',

    props: {
        data: Object
    },

    created() {
        let data = this.data;

        data.data.lists.map((v, i) => {

            // zongping
            if (i == 0 && v.zongping) {
                this.chartDataGauge.rows[0].value = v.zongping.defen
                this.chartSettingsGauge.seriesMap.w.axisLine.lineStyle.color[0][0] = v.zongping.defen / 100
            }

            // zongping
            if (v.zongping && v.zongping.defen) {
                v.chartSettings = {
                    limitShowNum: 2,
                    dimension: 'a',
                    metrics: 'b',
                    labelLine: {
                        show: false
                    }
                }
                v.chartData = {
                    columns: ['a', 'b'],
                    rows: [
                        { 'a': ' ', 'b': 100 - Number(v.zongping.defen) },
                        { 'a': '', 'b': Number(v.zongping.defen) },
                    ]
                }
            }
        })
        this.items = data.data.lists
    },

    data() {
        return {
            items: [],
            colors: ['#fd622e', '#ffcdba', '#eaeaea'],
            chartSettingsGauge: {
                seriesMap: {
                    w: {
                        radius: '94%',
                        startAngle: 180,
                        endAngle: 0,
                        splitNumber: 4,
                        axisLine: {
                            lineStyle: {
                                color: [[1, '#3b3bd2'], [1, '#b8b8ff']],
                                width: 25
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#fff',
                                width: 1
                            }
                        },
                        axisTick: {
                            length: 12,
                        },
                        axisLabel: {
                            textStyle: {
                                fontWeight: 'bolder',
                                color: '#3b3bd2',
                                shadowColor: '#3b3bd2',
                                shadowBlur: 10
                            }
                        },
                        detail: {
                            fontSize: 16,
                            fontWeight: 'bold'
                        }
                    }
                },
            },
            chartDataGauge: {
                columns: ['type', 'value'],
                rows: [
                    { type: 'w', value: 40 }
                ]
            }
        }
    }

}
<template lang="pug">
    .recommend
        .hd
            span 测评推荐
            router-link(:to="{name: 'Ceping'}") 更多&gt;
        .loop
            .item(v-for="(v, i) in items" :key="i")
                router-link(:to="{ name: 'Detail', query: { id: v.id } }" v-if="v.url == ''")
                    .pic 
                        img(:src="v.img")
                    .info
                        .title {{v.title}}
                        .hits {{v.hits}}人已测
                a(:href="v.url" v-else)
                    .pic 
                        img(:src="v.img")
                    .info
                        .title {{v.title}}
                        .hits {{v.hits}}人已测

</template>
<script type="text/javascript">
export default {
    name: 'Recommend',

    props: {
        items: {
            type: Array,
            default: []
        }
    }
}
</script>
<style lang="less" scoped>
.ellipsis                       {overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.recommend                      {background: #FFF;padding-bottom: .4rem;
    .hd                         {display: flex;align-items: center;padding: .7rem .4rem .4rem;width: 100%;justify-content: space-between;
        span                    {.fs(16);font-weight: 600;color: #333;}
        a                       {.fs(12);color: #a0a4a3;}
    }           
    .loop                       {display: flex;width: 100%;flex-wrap: wrap;padding: 0 .28rem;}
    .item                       {padding: 0 .12rem;width: 50%;box-sizing: border-box;}
    a                           {display: flex;flex-direction: column;
        .pic                    {position: relative;overflow: hidden;border-radius: .14rem .14rem 0 0;
            &:before            {content: '';display: block;padding-bottom: 2.4rem;}
            img                 {position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;}
        }           
        .info                   {border-top: none;padding: .22rem .1rem .1rem;
            .title              {.fs(14);font-weight: 600;color: #333;.ellipsis;}
            .hits               {.fs(12);color: #a0a4a3;}
        }
    }
}
</style>
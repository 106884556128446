import Vue from 'vue'
import VCharts from 'v-charts'
Vue.use(VCharts)
export default {
    name: 'tpl_7',

    props: {
        data: Object
    },

    created() {
        let data = this.data;

        data.data.lists.map((item, index) => {
            if (item.weidu) {
                item.weiduData = { columns: ['t', 'n'], rows: [] };
                item.weidu.map((v, i) => {
                    item.weiduData.rows.push({ t: v.title, n: v.defen })
                })
            }
        })
        this.items = data.data.lists
    },

    data() {
        return {
            loop: [],

            colors: ['#5ec2b5', '#85cec4'],
            chartSettings: {
                radius: 80,
                roseType: 'radius'
            }
        }
    }

}
import recommend from './components/recommend.vue'
import { config } from '@/utils'
import wx from 'weixin-js-sdk';
import tpl_1 from './tpl_1/index.vue'
import tpl_2 from './tpl_2/index.vue'
import tpl_3 from './tpl_3/index.vue'
import tpl_4 from './tpl_4/index.vue'
import tpl_5 from './tpl_5/index.vue'
import tpl_6 from './tpl_6/index.vue'
import tpl_7 from './tpl_7/index.vue'
import tpl_8 from './tpl_8/index.vue'
import tpl_9 from './tpl_9/index.vue'
import tpl_10 from './tpl_10/index.vue'
import tpl_11 from './tpl_11/index.vue'
import tpl_12 from './tpl_12/index.vue'
import tpl_13 from './tpl_13/index.vue'
import tpl_14 from './tpl_14/index.vue'
import { ceping, api } from '@/api'

export default {
    name: 'Report',

    components: {
        recommend,
        tpl_1,
        tpl_2,
        tpl_3,
        tpl_4,
        tpl_5,
        tpl_6,
        tpl_7,
        tpl_8,
        tpl_9,
        tpl_10,
        tpl_11,
        tpl_12,
        tpl_13,
        tpl_14
    },

    created() {
        // 判断是否有id
        if (!this.$route.query.id) {
            this.$toast('缺少模板id')
            return setTimeout(() => { this.$router.push({ name: 'Default' }) }, 500);
        }
        // 获取报告数据
        this.handleGetData()
    },

    methods: {
        // 获取报告数据
        handleGetData () {
            if (this.isAjax) return 
            this.$toast.loading({ message: '加载中...', forbidClick: true })
            this.isAjax = true
            ceping.report({
                sn: this.$route.query.sn
            }).then(res => {
                this.$toast.clear()
                this.isAjax = false
                if (res.code != 1) return this.$toast(res.message)
                document.title = res.info.title                
                this.item = res
                this.info = res.info
                this.recommend = res.data.recommend
                this.botstyle = res.data.botstyle
                this.isFinish = true
                this.handleShare(res.info); // 分享设置
            }).catch(err => {
                this.isAjax = false
                this.$toast.clear()
                console.log(err)
            })
        },

        // 永久保存报告
        handleSaveReport() {
            if (this.isAjax) return
            this.isAjax = true
            ceping.getticketbysn({
                sn: this.$route.query.sn
            }).then(res => {
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message)
                this.qrcodeUrl = res.data.ticket
                this.isOverlay = true
            }).catch(err => {
                this.isAjax = false
                console.log(err)
            })
        },

        // 分享
        async handleShare(item) {
            // console.log(location.href.split('#')[0], process.env.VUE_APP_DEBUG == 1 ? true : false)
            // if (!_global.wx || process.env.VUE_APP_CONFIG != 'test') return
            if (!_global.wx) return
            let url = location.href.split('#')[0];
            let res = await api.share({ url: encodeURIComponent(url) })
            wx.config({
                debug: process.env.VUE_APP_DEBUG == 1 ? true : false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.appId,
                timestamp: res.timestamp,
                nonceStr: res.nonceStr,
                signature: res.signature,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
            });

            wx.ready(function () {
                // 分享好友
                wx.updateAppMessageShareData({
                    title: item.title,                                  // 分享标题
                    desc: item.title,
                    link: url,                                          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致且分享的地址不要带端口
                    imgUrl: item.img,                                   // 分享图标
                    success: function (e) {
                        console.log('分享好友设置成功')
                    }
                });

                // 分享朋友圈
                wx.updateTimelineShareData({
                    title: item.title,                                  // 分享标题
                    link: url,                                          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致且分享的地址不要带端口
                    imgUrl: item.img,                                   // 分享图标
                    success: function (e) {
                        console.log('分享朋友圈设置成功')
                    }
                });
            })
        }
    },

    data() {
        return {
            isAjax: false,                  // 是否在请求
            isFinish: false,                // 请求是否完成
            isWeixin: _global.wx,           // 判断是否微信浏览器 true or false
            userInfo: _global.userInfo,     // 用户个人数据
            item: {},                       // 报告数据
            info: {},                       // banner 数据
            recommend: [],                  // 推荐测评数据
            botstyle: 0,                    // 2 显示通用导航栏
            isShare: false,                 // 是否分享引导弹窗
            isOverlay: false,               // 是否弹窗
            qrcodeUrl: '',                  // 二维码地址
        }
    }

}
import Vue from 'vue'
import VCharts from 'v-charts'
Vue.use(VCharts)
export default {
    name: 'tpl_10',

    props: {
        data: Object
    },

    created() {
        let data = this.data;
        let length = data.data.lists.length - 1;
        data.data.lists.map((v, i) => {
            
            // zongping
            if (v.zongping && v.zongping.defen) {
                this.chartDataGauge.rows[0].value = v.zongping.defen
                this.chartSettingsGauge.seriesMap.w.axisLine.lineStyle.color[0][0] = v.zongping.defen / 100
            }

            if (v.weidu && i < length) {
                v.zongpingData = { columns: ['i'], rows: [{ 'i': '' }, { 'i': '' }, { 'i': '' }] };
                v.weidu.map((val, index) => {
                    v.zongpingData.columns.push(val.title)
                    v.zongpingData.rows[0][val.title] = val.defen
                    v.zongpingData.rows[1][val.title] = val.zongfen
                    v.zongpingData.rows[2][val.title] = 100
                })
            }
            if (v.weidu && i >= length) {
                v.zongpingData = { rows: [] };
                v.weidu = v.weidu.reverse();
                v.weidu.map((val, index) => {
                    v.zongpingData.rows.push({title: val.title, defen: val.defen})
                })
            }

        })
        this.items = data.data.lists
    },

    data() {
        return {
            items: [],

            colors: ['#5ec2b5', '#d7eae8', '#eaeaea'],

            chartData: {
                columns: ['i', 'a'],
                rows: [
                    { 'i': '', 'a': '' },
                    { 'i': '', 'a': 100 }
                ]
            },


            chartSettingsGauge: {
                seriesMap: {
                    w: {
                        radius: '94%',
                        startAngle: 180,
                        endAngle: 0,
                        splitNumber: 4,
                        axisLine: {
                            lineStyle: {
                                color: [[1, '#5ec2b5'], [1, '#d7eae8']],
                                width: 25
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#fff',
                                width: 1
                            }
                        },
                        axisTick: {
                            length: 12,
                        },
                        axisLabel: {
                            textStyle: {
                                fontWeight: 'bolder',
                                color: '#5ec2b5',
                                shadowColor: '#5ec2b5',
                                shadowBlur: 10
                            }
                        },
                        detail: {
                            fontSize: 16,
                            fontWeight: 'bold'
                        }
                    }
                },
            },

            chartSettings: {
                areaStyle: {
                    opacity: .4
                },
                label: {
                    show: true,
                    fontSize: 10
                }
            },
            areaSettings: {
                dimension: ["title"], 
                metrics: ["defen"], 
                dataOrder: {   //按照由大到小进行排序
                  label: "zongfeng",
                  order: "desc",
                },
                itemStyle: {
                  normal: {   //这里没有使用百分数的单位，所以%是自己加上去的
                    label: { show: true, position: "right", formatter: "{c}%" },
                  },
                },
                max: [100, 100],
            },
            areaExtend: {
                "yAxis.0.splitLine.show": false, // y轴表格线不显示
                "xAxis.0.splitLine.show": false, // x轴标签不显示
                "xAxis.0.axisLabel.show": false, // x轴标签不显示
                yAxis: {
                  axisLabel: {
                    textStyle: {
                      color: "#9B9B9B", // y轴字体颜色
                      padding: [5, 5, 5, 5], // 上右下左
                    },
                  },
                },
                // 每个柱子
                series(v) {
                  // 设置柱子的样式
                  v.forEach((i) => {
                    i.barWidth = 14;  //柱子高度
                  });
                  return v;
                },
              }
        }
    }

}
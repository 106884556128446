import Vue from 'vue'
import VCharts from 'v-charts'
Vue.use(VCharts)
export default {
    name: 'tpl_10',

    props: {
        data: Object
    },

    created() {
        let data = this.data;

        data.data.lists.map((v, i) => {
            
            // zongping
            if (v.zongping && v.zongping.defen) {
                this.chartDataGauge.rows[0].value = v.zongping.defen
                this.chartSettingsGauge.seriesMap.w.axisLine.lineStyle.color[0][0] = v.zongping.defen / 100
            }

            if (v.weidu) {
                v.zongpingData = { columns: ['i'], rows: [{ 'i': '' }, { 'i': '' }, { 'i': '' }] };
                v.weidu.map((val, index) => {
                    v.zongpingData.columns.push(val.title)
                    v.zongpingData.rows[0][val.title] = val.defen
                    v.zongpingData.rows[1][val.title] = val.zongfen
                    v.zongpingData.rows[2][val.title] = 100
                })
            }
        })
        this.items = data.data.lists
    },

    data() {
        return {
            items: [],

            colors: ['#5ec2b5', '#d7eae8', '#eaeaea'],

            chartData: {
                columns: ['i', 'a'],
                rows: [
                    { 'i': '', 'a': '' },
                    { 'i': '', 'a': 100 }
                ]
            },


            chartSettingsGauge: {
                seriesMap: {
                    w: {
                        radius: '94%',
                        startAngle: 180,
                        endAngle: 0,
                        splitNumber: 4,
                        axisLine: {
                            lineStyle: {
                                color: [[1, '#5ec2b5'], [1, '#d7eae8']],
                                width: 25
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#fff',
                                width: 1
                            }
                        },
                        axisTick: {
                            length: 12,
                        },
                        axisLabel: {
                            textStyle: {
                                fontWeight: 'bolder',
                                color: '#5ec2b5',
                                shadowColor: '#5ec2b5',
                                shadowBlur: 10
                            }
                        },
                        detail: {
                            fontSize: 16,
                            fontWeight: 'bold'
                        }
                    }
                },
            },
            chartDataGauge: {
                columns: ['type', 'value'],
                rows: [
                    { type: 'w', value: 40 }
                ]
            },

            chartSettings: {
                areaStyle: {
                    opacity: .4
                },
                label: {
                    show: true,
                    fontSize: 10
                }
            }
        }
    }

}
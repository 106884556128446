import Vue from 'vue'
import VCharts from 'v-charts'
Vue.use(VCharts)
export default {
    name: 'tpl_2',

    props: {
        data: Object
    },

    created() {
        let data = this.data;
        this.items = data.data.lists
    },

    data() {
        return {
            items: [],
        }
    }

}
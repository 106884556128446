import Vue from 'vue'
import VCharts from 'v-charts'
Vue.use(VCharts)
export default {
    name: 'tpl_1',

    props: {
        data: Object
    },

    created() {
        let data = this.data;
        data.data.lists.map((item, index) => {
            if (item.weidu) {
                // zongping 设置参数
                item.zongpingData = { columns: ['i'], rows: [{ 'i': '' }, { 'i': '' }, { 'i': '' }] };

                // weidu 设置参数
                item.weiduSettings = []
                item.weiduData = []

                item.weidu.map((v, i) => {
                    // zongping 数据设置
                    item.zongpingData.columns.push(v.title)
                    item.zongpingData.rows[0][v.title] = v.defen
                    item.zongpingData.rows[1][v.title] = v.pingjun
                    item.zongpingData.rows[2][v.title] = v.zongfen
                    // item.zongpingData.rows[3][v.title] = 100

                    // weidu 数据设置
                    item.weiduSettings[i] = { stack: { 'xxx': ['defen', 'zongfen'] }, max: [v.zongfen] };
                    item.weiduData[i] = {
                        columns: ['i', 'defen', 'zongfen'],
                        rows: [{ 'i': '', 'defen': v.defen, 'zongfen': v.zongfen }]
                    }
                })
            }

        })
        this.items = data.data.lists
    },

    data() {
        return {
            items: [],

            colors: ['#5ec2b5', '#fdff75', '#d7eae8'],
            colorsbar: ['#5ec2b5', '#d7eae8'],
            chartSettingsWeidu: [],
            chartDataWeidu: [],

            chartSettings: {
                areaStyle: {
                    opacity: .4
                },
                label: {
                    show: true,
                    fontSize: 10
                }
            },
            chartData: {
                columns: ['i'],
                rows: [{'i': ''},{'i': ''}]
            }
        }
    }

}